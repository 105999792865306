interface DebtJourneyStep {
  name: string;
  restricted: boolean;
}
interface Tenant {
  [otherOptions: string]: unknown;
  sessionLimiter: any;
  gasSafetyNotice?: boolean;
  electricSafetyNotice?: boolean;
  cooldownPeriodHours?: number;
  licenses?: any[];
  pusherApiKey?: string;
  debtJourneySteps?: DebtJourneyStep[];
  reservationTimeOutMinutes: number;
  skipSsdValidation?: boolean;
  customerDetailsPartnerDescriptionEnabled?: boolean;
  sipEesLookupEnabled?: boolean;
  hideAdvancedMenuOptions?: boolean;
}

enum CapacityModeEnum {
  STRICT = 'STRICT',
  FLEXIBLE = 'FLEXIBLE'
}

export { Tenant, DebtJourneyStep, CapacityModeEnum };
